import(/* webpackMode: "eager" */ "/var/www/staging.truligo.com/proxio-fe/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/var/www/staging.truligo.com/proxio-fe/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/staging.truligo.com/proxio-fe/src/components/GoogleTagManagerInit/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/staging.truligo.com/proxio-fe/src/components/ReCaptchaProvider/ReCaptchaProvider.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/staging.truligo.com/proxio-fe/src/styles/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/staging.truligo.com/proxio-fe/src/theme.ts");
